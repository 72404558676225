module.exports =
  ({ node, key, value }) =>
  (doc) => {
    // console.log(doc);

    if (doc.type === "page" && doc.data?.home_page) {
      return "/";
    }
    const prefix = doc.lang === "en-gb" ? "" : "/fr";

    switch (doc.type) {
      case "category":
        return `${prefix}/category/${doc.uid}`;

      case "project":
        return `${prefix}/project/${doc.uid}`;

      default:
        return `${prefix}/${doc.uid}`;
    }
  };
